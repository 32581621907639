import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/Seo";
import Intro from "../components/Intro";

const EventsTemplate = ({ title, text, link, linkText, intro }) => {
  return (
    <div className="section">
      <div className="w-full max-w-screen-xl px-6 mx-auto">
        <div className={`text-center md:text-left pt-8 pb-12 md:py-16`}>
          <h1 className="lg:pr-12">{title}</h1>
        </div>
      </div>
      {intro.title || intro.introImage || intro.link || intro.text ? (
      <div class="mb-16 md:mb-20">
        <Intro
          introImage={intro.introImage}
          title={intro.title}
          text={intro.text}
          link={intro.link}
          linkText={intro.linkText}
          introToggle={intro.introToggle ? intro.introToggle : false}
          theme="bg-white"
        />
      </div>
      
      ) : null}
      <div className={`mx-auto max-w-screen-xl w-full px-6`}>
        <div className={`bg-[#448FDE] text-white py-20 text-center`}>
          <div className={`max-w-xl m-auto`}>
            <h2 className={`mb-6 font-medium`}>{text}</h2>
            {link && (
              <a
                href={link}
                target="_blank"
                rel="noreferrer nofollow noopener"
                className={`bg-white text-sm text-[#448FDE] inline-block font-semibold py-2 px-6 rounded-[10px]`}
              >
                {" "}
                {linkText}
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

EventsTemplate.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  linkText: PropTypes.string,
  link: PropTypes.string,
};

const EventsPage = ({ data }) => {
  const frontmatter = data.markdownRemark.frontmatter;

  return (
    <Layout>
       <Seo title={frontmatter.title} description={frontmatter.text} />

      <EventsTemplate
        title={frontmatter.title}
        intro={frontmatter.intro}
        text={frontmatter.text}
        linkText={frontmatter.linkText}
        link={frontmatter.link}
      />
    </Layout>
  );
};

EventsPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export const eventsPageQuery = graphql`
  query EventsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        text
        linkText
        link
        intro {
          title
          text
          link
          linkText
          introImage {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
export default EventsPage;